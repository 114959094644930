<template>
    <div>
        <page-header
            :title="$t('pages.autoBalanceTransfer.information.header')"
            show-back
            @onClose="returnToRoot"
        />

        <div class="container text-center">
            <img
                src="@/assets/images/components/modal/recurring-calendar.svg"
                alt="Auto transfer information"
            >

            <h5
                class="text-center fw-light my-1"
                v-html="$t('pages.autoBalanceTransfer.information.title')"
            />

            <div class="text-muted text-center mb-3">
                <span v-html="$t('pages.autoBalanceTransfer.information.message')" />
            </div>

            <div class="d-grid">
                <button
                    class="btn btn-primary mb-2"
                    @click="getStartedWithAutoBalanceTransfer"
                >
                    {{ $t('pages.autoBalanceTransfer.information.ctaPrimary') }}
                </button>
            </div>

            <div class="d-grid">
                <button
                    class="btn btn-secondary mb-2"
                    @click="doneWithInformation"
                >
                    {{ $t('pages.autoBalanceTransfer.information.ctaSecondary') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '@/components/PageHeader'
    import { navigation } from '@/mixins/navigation'
    import { RoutePaths } from '@/routes/router'
    import { mapActions } from 'vuex'

    export default {
        name: 'RecurringBalanceTransferInformation',
        components: {
            PageHeader,
        },
        mixins: [navigation],
        data: function () {
            return {
                RoutePaths,
            }
        },
        methods: {
            ...mapActions(['getActiveBalanceTransferAccountsEligibleForRecurrence']),
            getStartedWithAutoBalanceTransfer: function () {
                this.$logEvent('click_button_turn_on_recurring_balance_transfer')
                // fromDeeplink=true informs the 'close' navigation behavior of the select card view.
                // True means when we close that view, we'll navigate back to the root view.
                this.$router.replace({ path: RoutePaths.RECURRING_BT_SELECT_CARDS, query: { fromDeeplink: 'true' } })
            },
            doneWithInformation: function () {
                this.$logEvent('click_button_done_with_balance_transfer_information')
                this.returnToRoot()
            },
        },
        mounted: function () {
            // Grab the active balance transfer accounts here so they're ready for the
            // card selection screen. Note the method below is async so as not to block
            // loading.
            this.getActiveBalanceTransferAccountsEligibleForRecurrence()
            this.$logEvent('view_recurring_balance_transfer_information')
        },
    }
</script>
